@import '../../styles/common';

.header {
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;

  @media (min-width: 720px) {
    padding: 0 56px;
  }

  &-logo {
    width: 96px;
    // margin:  12px;
    @media (min-width: 720px) {
      // margin-left: 56px;
    }
  }
  .menuIcon {
    margin-right: 12px;
    fill: $primary-green;
    background: none;
    border: none;
    cursor: pointer;
    svg {
      width: 36px;
      height: 36px;
    }

    @media (min-width: 720px) {
      margin-right: 56px;
    }
  }

  &-links {
    font-size: 20px;
    margin-right: 12px;
    @media (min-width: 720px) {
      margin-right: 56px;
    }
  }
}

@keyframes openRight {
  0% {
    right: -200px;
  }

  100% {
    right: 0px;
  }
}

.sideNav {
  height: 100vh;
  width: 60vw;
  position: fixed;
  right: -60vw;
  top: 0;
  background-color: $primary-blue;
  z-index: 200;
  transition: right 1s;

  @media (min-width: 720px) {
    width: 350px;
  }
  &.open {
    right: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 50%;

    .closeIcon {
      width: fit-content;
      align-self: end;
      background: none;
      border: none;
      svg {
        width: 28px;
        height: 28px;
        fill: $primary-green;
      }
    }
  }
  a {
    margin: 0;
  }
  button {
    width: 90%;
    margin: 0;
  }
}
.header-link {
  background: none;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  color: $primary-lightest;
  text-decoration: none;
  margin-left: 24px;
}
.header-button {
  font-size: 20px;
  margin-left: 24px;
  padding: 8px 18px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  &.outlined {
    border: 1px solid $primary-green;
    border-radius: 6px;
    color: $primary-green;
  }

  &.primary {
    border-radius: 6px;
    background-color: $primary-green;
    color: $primary-blue;
  }
}
