@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Ultralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('../assets/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

$primary-lightest: #fff;
$primary-grey: #ededed;
// $primary-green: #88ff82;
$primary-green: #01ff00;
$primary-blue: #0d426a;
$primary-dark-blue: #051727;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInWithScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes openTop {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes closeTop {
  0% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(0);
  }
}
