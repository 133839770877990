@import './common';

.landing {
  display: flex;
  position: relative;
  flex-direction: column;

  &-home {
    height: 80vh;
    max-height: 900px;
  }

  &-search-section {
    width: 100%;
    height: 70vh;
    max-height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    h1 {
      display: flex;
      flex-direction: column;
      color: $primary-lightest;
      font-size: 32px;
      font-weight: 300;
      max-width: 90vw;
      span.green {
        color: $primary-green;
      }

      @media (min-width: 580px) {
        font-size: 48px;
      }
    }
  }

  .banner {
    max-width: 1200px;
    width: 80vw;
    margin: 96px 0;
  }
}
