@import '../../../styles/common';

.playlocal-third-slide {
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &-images {
    width: 100vw;
    height: 100vh;
    max-height: 900px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -6;

    img {
      object-fit: cover;
      background-repeat: no-repeat;
    }

    &-first {
      display: flex;
      height: 50vh;

      img:nth-child(1) {
        width: 50%;
        object-position: center;
      }

      img:nth-child(2) {
        width: 50%;
      }
    }

    &-second {
      display: flex;
      height: 50vh;

      img:nth-child(1) {
        width: 50%;
      }

      img:nth-child(2) {
        width: 50%;
        object-position: top;
      }
    }

    &-overlay {
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100vh;
      top: 0;
      opacity: 0.64;
      z-index: 0;
    }
  }

  &-subtitles {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: $primary-lightest;
    line-height: 1.5;
    width: 100%;
    gap: 24px;
    margin-bottom: 50px;

    @media (min-width: 960px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    h2 {
      margin: 0 12px;
      font-size: 30px;

      @media (min-width: 720px) {
        margin-left: 120px;
      }
      @media (min-width: 480px) {
        font-size: 42px;
      }

      @media (min-width: 980px) {
        font-size: 50px;
      }
    }

    .green {
      color: $primary-green;
    }
  }

  &-store-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 0 12px;

    @media (min-width: 720px) {
      margin: 0 120px;
    }

    a {
      img {
        width: 15vw;
        min-width: 200px;
        height: auto;
      }
    }
  }
}

.third-slide-store-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  margin: 0 12px;

  @media (min-width: 720px) {
    flex-direction: row;
    margin: 0 120px;
  }

  a {
    img {
      width: 180px;
      height: 60px;
    }

    img:hover {
      transform: scale(1.1);
      transition: transform 0.5s;
    }
  }
}
