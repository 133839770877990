@import '../../../styles/common';

.contact-form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.contact-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 80%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    padding-bottom: 70px;
  }
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: black;
  text-align: right;
  width: 100%;
  position: sticky;
}

.close-button:hover {
  color: #555;
}

.logo {
  width: 70%;
  margin: 20px 50px 20px 50px;
  @media (max-width: 600px) {
    width: 100%;
    margin: 20px 0px 40px 0px;
    max-width: 200px;
  }
}

.contact-short-message {
  width: 100%;
  text-align: center;
  font-weight: 400;
  margin: 0px 0px 20px 0px;
  .sub-title {
    color: black;
  }
}

@import '../../../styles/common';

.request-form {
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  &.only-form {
    height: 190px;
    margin-bottom: 24px;
  }
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  h3 {
    margin: 0 0 12px 0;
    font-size: 48px;
    font-weight: 400;
    color: $primary-lightest;
    transition: font-size 1s;
  }

  span.read-more {
    font-size: 16px;
    margin-bottom: 24px;

    &.green {
      color: $primary-green;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  span {
    font-size: 24px;
    font-weight: 400;
    color: $primary-lightest;
    transition: font-size 1s;
  }

  label {
    padding-left: 5px;
  }

  input {
    width: 100%;
    padding-left: 5px;
    background: $primary-lightest;
    border: 1px solid $primary-grey;
    border-radius: 5px;
    font-size: 16px;
    line-height: 2;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
    &::placeholder {
      opacity: 0.5;
      padding: 0;
      margin: 0;
      line-height: 0;
      padding-left: 5px;
    }
  }
  input:focus {
    border: 1px solid $primary-blue;
    background: $primary-grey;
  }

  .error {
    color: rgb(211, 62, 62);
    font-size: 14px;
    margin-top: 12px;
  }

  .contact-btn {
    height: 40px;
    width: 100%;
    padding: 5px;
    margin-top: 30px;
    border: none;
    font-size: 22px;
    color: $primary-lightest;
    background-color: $primary-blue;
    cursor: pointer;
    border-radius: 5px;
    @media (max-width: 600px) {
      margin-top: 10px;
      padding: 7px;
      height: 40px;
    font-size: 18px;
    }
  }
  .inputPosition {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    @media (min-width: 700px) {
      flex-direction: row;
      height: 30px;
    }
  }
}
