@import '../../styles/common';

.welcome-image {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 12px;

  @media (min-width: 720px) {
    padding: 0 60px;
  }

  @media (min-width: 960px) {
    padding: 0 120px;
  }

  &-background {
    width: 100vw;
    max-height: 900px;
    top: 0;
    z-index: -5;
  }
  &-content {
    width: 100%;
    height: 80vh;
    max-height: 900px;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -6;
  }
  &-overlay {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.64;
    z-index: 0;
  }

  &-title {
    display: flex;
    flex-direction: column;
    color: $primary-lightest;
    text-transform: uppercase;
    font-weight: bold;
    transition: font-size 1s;
    font-size: 28px;
    h1 {
      font-weight: bold;
      margin: 0;
      opacity: 0;
      animation: fadeIn 500ms forwards;
    }
    .label {
      font-size: 20px;
      font-weight: bold;
      opacity: 0;
      text-transform: uppercase;
      animation: fadeIn 500ms 500ms forwards;
    }
    .green {
      color: $primary-green;
    }

    @media (min-width: 480px) {
      font-size: 42px;
    }

    @media (min-width: 980px) {
      font-size: 48px;
    }
  }

  &-subtitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $primary-lightest;
    margin-top: 56px;
    line-height: 1.5;
    font-size: 20px;
    gap: 20px;

    div {
      margin-top: 24px;
    }

    h2 {
      opacity: 0;
      animation: fadeIn 300ms 1s forwards;
      margin: 0;
    }

    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 300;
      width: 100%;
      opacity: 0;
      animation: fadeIn 300ms 1.3s forwards;
      a {
        text-decoration: none;
        color: white;
        margin-left: 6px;
        border-bottom: 1px solid $primary-green;
      }
    }
    .green {
      color: $primary-green;
    }

    @media (min-width: 480px) {
      h2 {
        font-size: 42px;
      }
      h3 {
        font-size: 20px;
      }
    }

    @media (min-width: 740px) {
      align-items: baseline;
      div {
        margin-top: 0;
      }
      h2 {
        margin: 0;
      }
    }

    @media (min-width: 980px) {
      h2 {
        font-size: 50px;
        letter-spacing: 1.2px;
      }

      h3 {
        font-size: 24px;
      }
    }
  }
}

@for $i from 1 to 10 {
  .welcome-image-subtitle h2:nth-child(#{$i}) {
    animation-delay: $i * 0.45s + 1s;
  }
}

.bg-top {
  background-position: top;
}
