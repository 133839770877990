@import '../../styles/common';

.footer {
  width: 100%;
  height: 246px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  background-color: $primary-dark-blue;
  z-index: 100;

  @media (min-width: 700px) {
    justify-content: space-between;
    flex-direction: row;
  }
  span {
    color: #fff;
    font-size: 16px;
    // @media (min-width: 480px) {
    //   font-size: 20px;
    // }
  }
  .logo-content {
    display: flex;
    flex-direction: column;
    margin-left: 0;

    @media (min-width: 700px) {
      margin-left: 42px;
    }
    @media (min-width: 840px) {
      margin-right: 72px;
    }
    img {
      width: 230px;
      margin-bottom: 12px;

      @media (min-width: 480px) {
        width: 284px;
      }
    }
  }

  .social-media {
    width: 265px;
    margin-right: 0;
    text-align: center;

    @media (min-width: 700px) {
      margin-right: 42px;
    }
    @media (min-width: 840px) {
      margin-right: 72px;
    }

    .social-media-icons {
      display: flex;
      margin-top: 12px;
      justify-content: space-between;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 44px;
        background-color: #fff;
      }
    }
  }
}
