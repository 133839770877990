@import '../../../styles/common';

.image-section {
  position: relative;
  display: flex;
  width: 50%;
  &.fullW {
    width: 100%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  &.fadeIn {
    img {
      animation: fadeInWithScale 1s forwards;
    }

    h1 {
      animation: fadeIn 1s 0.75s forwards;
    }
    h3 {
      animation: fadeIn 1s 0.75s forwards;
    }
  }

  img {
    width: 100%;
    opacity: 0;
  }

  h1, h2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0;
    bottom: 18px;
    left: 18px;
    z-index: 75;
    font-size: 24px;
    color: $primary-lightest;
    opacity: 0;
    transition: font-size 1s, bottom 1s, left 1s;
    .title-green {
      color: $primary-green;
    }

    @media (min-width: 480px) {
      font-size: 32px;
      bottom: 24px;
      left: 24px;
    }

    @media (min-width: 860px) {
      bottom: 48px;
      left: 48px;
      font-size: 48px;
    }
    @media (min-width: 980px) {
      font-size: 56px;
    }

    @media (min-width: 1250px) {
      font-size: 72px;
    }
  }

  h3 {
    display: flex;
    // flex-direction: column;
    gap: 12px;
    position: absolute;
    margin: 0;
    bottom: 18px;
    left: 18px;
    z-index: 75;
    font-size: 24px;
    color: $primary-lightest;
    opacity: 0;
    transition: font-size 1s, bottom 1s, left 1s;
    .title-green {
      color: $primary-green;
    }

    @media (min-width: 480px) {
      font-size: 32px;
      bottom: 24px;
      left: 24px;
    }

    @media (min-width: 860px) {
      bottom: 48px;
      left: 48px;
      font-size: 48px;
    }
    @media (min-width: 980px) {
      font-size: 56px;
    }

    @media (min-width: 1250px) {
      font-size: 72px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
  }
}
