@import '../../styles/common';

.toast {
  width: 284px;
  min-height: 86px;
  position: fixed;
  bottom: 12px;
  right: -380px;
  display: flex;
  align-items: center;

  background: linear-gradient(
    89.8deg,
    #166447 0.18%,
    #0d4f59 14.71%,
    #0d4465 29.25%,
    #0e4d7b 99.84%
  );

  border-radius: 5px;
  padding: 12px 24px;
  opacity: 1;
  transition: right 0.5s;
  z-index: 300;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  &.active {
    right: 24px;
  }

  @media (min-width: 480px) {
    width: 380px;
    min-height: 112px;

    bottom: 48px;
    right: -450px;
    &.active {
      right: 48px;
    }
  }

  &-icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    border-radius: 84px;
    background: #37e12e;

    @media (min-width: 480px) {
      width: 42px;
      height: 42px;
    }
  }

  &-message {
    display: flex;
    flex-direction: column;
    max-width: 220px;

    @media (min-width: 480px) {
      max-width: 285px;
    }
  }

  &-title {
    font-size: 20px;
    color: $primary-lightest;
    @media (min-width: 480px) {
      font-size: 24px;
    }
  }

  &-description {
    color: $primary-lightest;
    font-size: 14px;
    margin: 12px 0;
    @media (min-width: 480px) {
      font-size: 16px;
    }
  }
}
