@import '../src/styles/common'; // check if we can import it only here

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  user-select: none;
  -webkit-user-select: none;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'SF-Pro-Display';
  overflow-y: scroll;
  background-color: $primary-blue;
}

#root {
  width: 100%;
  height: 100%;
}
