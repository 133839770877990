@import '../../../styles/common';

.hero {
  position: relative;
  display: flex;
  width: 100vw;
  min-height: 93vh;
  overflow-x: hidden;
  &.fullW {
    width: 100%;
  }

  &.fadeIn {
    img {
      animation: fadeInWithScale 1s forwards;
    }
    input {
      animation: fadeInWithScale 1s forwards;
    }
    button {
      animation: fadeInWithScale 1s forwards;
    }

    h1 {
      animation: fadeIn 1s 0.75s forwards;
    }

    h2 {
      animation: fadeIn 1s 0.75s forwards;
    }
  }

  img:first-child {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -2;
  }

  .hero-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 100px;
    padding: 10px 15px 50px 15px;

    @media (min-width: 1020px) {
      padding: 20px 150px 50px 150px;
    }
    @media (max-width: 1020px) {
      padding: 20px 100px 50px 100px;
      margin-top: 120px,
    }
    @media (max-width: 800px) {
      padding: 20px 60px 50px 60px;
    }
    @media (max-width: 600px) {
      padding: 20px 30px 50px 30px;
    }
    @media (max-width: 430px) {
      padding: 20px 20px 50px 20px;
    }
  }

  .content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    align-self: center;
    gap: 30px;
    @media (max-width: 1024px) {
      flex-direction: column;
      text-align: center;
    }
  }
  .section-title {
    position: relative;
    width: 100%;
    h3 {
      color: $primary-lightest;
      position: relative;
      font-size: 46px;
      line-height: 56px;
      text-align: center;
      font-weight: 400;
      margin: 0;
      .title-green {
        color: $primary-green;
      }
      @media (max-width: 1260px) {
        font-size: 38px;
        line-height: 48px;
      }
      @media (max-width: 860px) {
        font-size: 30px;
        line-height: 40px;
      }
      @media (max-width: 430px) {
        font-size: 22px;
        line-height: 32px;
      }
      @media (max-width: 360px) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  .content-gap {
    padding-top: 40px;
    @media (max-width: 630px) {
      padding-top: 20px;
    }
  }
  .compare {
    margin: 50px 0 0 0;
    @media (max-width: 600px) {
      margin: 30px 0 0 0;
    }
    h2 {
      color: $primary-lightest;
      position: relative;
      display: flex;
      flex-direction: column;
      line-height: 44;
      font-size: 34px;
      .title-green {
        color: $primary-green;
      }
      @media (max-width: 1260px) {
        font-size: 30px;
      }
      @media (max-width: 800px) {
        font-size: 26px;
      }
      @media (max-width: 40px) {
        font-size: 22px;
      }
    }
  }

  .gap {
    height: 80px;
    width: 100%;
    @media (max-width: 600px) {
      height: 60px;
    }
  }
  .short-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .short-content-divider {
    display: none;
    @media (max-width: 730px) {
    display: block;
    width: 100%;
    height: 2px;
    background-color: $primary-lightest;
    opacity: 0.1;
    };
  };

  .short-message {
    position: relative;
    max-width: 90%;
  }
    .short-message h4 {
      font-size: 34px;
      color: $primary-lightest;
      transition: font-size 1s;
      margin-top: 10px;
      font-weight: 400;
      line-height: 44px;
      @media (max-width: 1260px) {
        font-size: 30px;
        line-height: 40px;
      }
      @media (max-width: 800px) {
        font-size: 26px;
        line-height: 36px;
      }
      @media (max-width: 430px) {
        font-size: 20px;
        line-height: 32px;
      }
      @media (max-width: 360px) {
        font-size: 18px;
        line-height: 28px;
      }
    }

    .title-green {
      color: $primary-green;
    }

  .shortmsg-pre {
    margin-bottom: 25px;
    @media (max-width: 600px) {
      margin-bottom: 0px;
    }
  }

  .shortmsg-sub {
    color: $primary-lightest;
    font-size: 34px;
    line-height: 44px;
    @media (max-width: 1020px) {
      font-size: 30px;
      line-height: 40px;
    }
    @media (max-width: 800px) {
      font-size: 26px;
      line-height: 36px;
    }
    @media (max-width: 500px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .shortmsg-link {
    text-decoration: underline;
  }
  

  .appStore {
    position: relative;
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    @media (max-width: 920px) {
      flex-direction: column;
      justify-content: flex-start;
    }
    .newsletter {
      display: flex;
      flex-direction: row;
    }
    input {
      width: 350px;
      background-color: transparent;
      border: 2px solid white;
      padding: 0 0 0 1rem;
      color: white;
      font-weight: 700;
      height: 50px;
      @media (max-width: 1020px) {
        width: 300px;
      }
      @media (max-width: 600px) {
        width: 240px;
      }
      @media (max-width: 400px) {
        width: 200px;
      }
    }
    ::placeholder {
      color: white;
    }
    :focus::placeholder {
      color: gray;
    }
    :focus {
      outline: none;
      border: 2px solid $primary-green;
    }
    button {
      padding: 0 2rem;
      background-color: $primary-green;
      font-weight: 400;
      border: none;
      cursor: pointer;
      font-weight: bold;
      height: 54px;
      font-size: 16px;
      border-radius: 5px;
      width: 200px;
    }
    @media (max-width: 600px) {
      button {
        padding: 0 1rem;
        background-color: $primary-green;
        font-weight: 200;
        border: none;
        cursor: pointer;
        font-weight: bold;
        height: 40px;
        font-size: 14px;
        width: 150px;
        border-radius: 5px;
        margin-top: 50px;;
      }
    }
    .appStoreImage {
      display: flex;
      justify-content: space-around;
      position: relative;
      gap: 20px;
      .app-image {
        cursor: pointer;
        position: relative;
        height: 54px;
        z-index: 0;
        object-fit: contain;
        width: auto;
        @media (max-width: 600px) {
          height: 40px;
        }
      }
    }
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
