@import '../../../styles/common';

.sporty-landing-home {
  height: 100vh;
}

.sporty-slide {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  display: flex;

  &-container {
    width: 80%;
    flex: 1;
    position: relative;
  }

  &-images {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -6;

    &-container {
      display: flex;
      flex-wrap: wrap;
      height: 100vh;

      img {
        width: 50%;
        height: 33vh;
        object-fit: cover;
        background-repeat: no-repeat;
      }

      @media (min-width: 960px) {
        img:nth-child(1),
        img:nth-child(5) {
          max-width: 30%;
          height: 50vh;
        }

        img:nth-child(1) {
          object-position: top;
        }

        img:nth-child(2),
        img:nth-child(3),
        img:nth-child(5),
        img:nth-child(6) {
          object-position: bottom;
        }

        img:nth-child(2),
        img:nth-child(3),
        img:nth-child(4),
        img:nth-child(6) {
          max-width: 35%;
          height: 50vh;
        }
      }
    }

    &-overlay {
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100vh;
      top: 0;
      opacity: 0.75;
      z-index: 0;
    }
  }

  &-subtitles {
    display: flex;
    flex-direction: column;
    color: $primary-lightest;
    line-height: 1.5;
    font-size: 20px;
    width: 100%;

    @media (min-width: 960px) {
      flex-direction: row;
      align-items: center;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    h2 {
      transition: all 0.3s ease-in-out;
      margin: 0 12px;

      @media (min-width: 720px) {
        margin-left: 120px;
      }
    }

    .green {
      color: $primary-green;
    }
  }
}

.sporty-colors {
  flex: 1;
  display: flex;
  max-width: 100px;
  &-green {
    flex: 1;
    width: 25%;
    background-color: $primary-green;
  }

  &-transparent {
    flex: 1;
    width: 25%;
    background-color: transparent;
  }
}

.sporty-logo {
  position: absolute;
  top: 30px;
  right: 12px;
  width: 96px;

  @media (min-width: 720px) {
    right: 56px;
  }

  z-index: 100;
}

.welcome-image-box {
  margin-top: 56px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.sporty-store-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 10px 12px;

  @media (min-width: 720px) {
    margin: 50px 120px;
  }

  a {
    img {
      width: 180px;
      height: 60px;
    }
  }
}
