@import '../../styles/common';

.landing-page {
  // height: 100vh;
  width: 100%;
  position: relative;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  user-select: none;
  -webkit-user-select: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    height: 80px;
    width: 100%;
    z-index: 100;

    transition: padding 1s;

    @media (min-width: 1020px) {
      height: 120px;
    }

    img {
      margin-left: 12px;
      height: 54px;
      padding: 30px 0 0 20px;
      transition: width 1s, margin 1s;

      @media (min-width: 600px) {
        height: 60px;
      }

      @media (min-width: 1020px) {
        height: 80px;
      }
    }

    a {
      color: $primary-green;
      padding: 12px 18px;
      border: 2px solid $primary-green;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
      margin-right: 12px;
      transition: padding 1s, margin 1s, font-size 1s;

      @media (min-width: 480px) {
        padding: 18px 24px;
        font-size: 18px;
      }

      @media (min-width: 1020px) {
        margin-right: 32px;
        padding: 28px 36px;
        font-size: 24px;
      }
    }
  }

  &-footer {
    width: 100%;
    height: 246px;
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    justify-content: space-around;
    align-items: center;
    background-color: $primary-dark-blue;
    z-index: 100;

    @media (min-width: 700px) {
      justify-content: space-between;
      flex-direction: row;
    }
    span {
      color: #fff;
      font-size: 16px;
      @media (min-width: 480px) {
        font-size: 20px;
      }
    }
    .logo-content {
      display: flex;
      flex-direction: column;
      margin-left: 0;

      @media (min-width: 700px) {
        margin-left: 42px;
      }
      @media (min-width: 840px) {
        margin-right: 72px;
      }
      img {
        width: 230px;
        margin-bottom: 12px;

        @media (min-width: 480px) {
          width: 284px;
        }
      }
    }

    .social-media {
      width: 265px;
      margin-right: 0;
      text-align: center;

      @media (min-width: 700px) {
        margin-right: 42px;
      }
      @media (min-width: 840px) {
        margin-right: 72px;
      }

      .social-media-icons {
        display: flex;
        margin-top: 12px;
        justify-content: space-between;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 32px;
          width: 32px;
          border-radius: 44px;
          background-color: #fff;
        }
      }
    }
  }

  .float-btn {
    width: 48px;
    height: 48px;
    position: fixed;
    right: 18px;
    bottom: 18px;
    z-index: 100;
    background-color: $primary-green;
    box-shadow: 3px 3px 3px $primary-blue;
    font-size: 24px;
    padding: 6px;
    border: none;
    border-radius: 80px;
    opacity: 0;
    cursor: pointer;

    animation: fadeIn 1s forwards;
    svg {
      height: 32px;
      width: 32px;
      fill: $primary-blue;
    }

    @media (min-width: 480px) {
      width: 64px;
      height: 64px;
      right: 48px;
      bottom: 48px;
      svg {
        height: 42px;
        width: 42px;
        fill: $primary-blue;
      }
    }
  }

  form {
    opacity: 0;
    animation: fadeIn 1s 1s forwards;

    @media (max-width: 480px) {
      max-height: 230px;
      h3 {
        font-size: 28px;
      }
      span {
        font-size: 18px;
      }
      input {
        font-size: 16px;
      }

      button {
        height: 48px;
        font-size: 18px;
      }
    }

    @media (min-width: 481px) and (max-width: 1020px) {
      h3 {
        font-size: 36px;
      }
      span {
        font-size: 24px;
      }
    }
  }

  &-welcompe-section {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 96px 48px;

    transition: margin 1s;

    @keyframes sectionTitle {
      0% {
        transform: scale(0);
        opacity: 0;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    @media (min-width: 1020px) {
      align-items: center;
      margin: 36px 48px;
    }

    @media (min-width: 1200px) {
      margin: 36px 142px;
    }

    .content {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      @media (min-width: 1020px) {
        width: 100%;
        height: fit-content;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: row;
      }
    }

    .section-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        display: flex;
        flex-direction: column;
        margin: 0 0 32px 0;
        font-size: 56px;
        font-weight: 400;
        color: $primary-lightest;
        animation: fadeIn 1s forwards;
        opacity: 0;
        transition: font-size 1s;

        @media (min-width: 480px) {
          font-size: 64px;
        }

        @media (min-width: 1020px) {
          font-size: 72px;
        }

        @media (min-width: 1600px) {
          font-size: 94px;
        }

        .title-green {
          color: $primary-green;
        }
      }
      h2 {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 28px;
        font-weight: 400;
        color: $primary-lightest;
        animation: fadeIn 1s 0.5s forwards;
        opacity: 0;
        transition: font-size 1s;

        @media (min-width: 480px) {
          font-size: 36px;
        }

        @media (min-width: 1020px) {
          font-size: 48px;
        }
        @media (min-width: 1600px) {
          font-size: 64px;
        }
        .title-green {
          color: $primary-green;
        }
      }
    }
    form {
      @media (min-width: 1021px) and (max-width: 1600px) {
        h3 {
          font-size: 36px;
        }
      }
    }
  }

  &-images-section {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;

    &-title {
      margin: 144px 48px;
      font-weight: 400;
      font-size: 46px;
      color: white;
      display: flex;
      flex-direction: column;
      span.green {
        color: $primary-green;
      }
    }
  }

  &-info-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 650px;

    .benefits {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      gap: 24px;
      justify-content: center;

      h2 {
        font-size: 32px;
        color: #fff;
        .green {
          color: $primary-green;
        }
      }
      ul {
        list-style: none;
        padding: 0;
      }
      li::before {
        content: '•';
        color: $primary-green;
        padding-right: 12px;
      }
      li {
        color: #fff;
        font-size: 20px;
      }
    }

    h1 {
      color: $primary-lightest;
      font-size: 42px;
      font-weight: 400;
      transition: font-size 1s;

      span {
        color: $primary-green;
      }
      @media (min-width: 480px) {
        font-size: 56px;
      }

      @media (min-width: 620px) {
        font-size: 72px;
      }

      @media (min-width: 1020px) {
        font-size: 96px;
      }
    }
  }
}
