@import '../../../styles/common';

.skillsharer-second-slide {
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-images {
    width: 100vw;
    height: 100vh;
    max-height: 900px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -6;

    img {
      object-fit: cover;
      background-repeat: no-repeat;
    }

    &-first {
      display: flex;
      height: 50vh;

      img:nth-child(1) {
        width: 50%;
        object-position: center;
      }

      img:nth-child(2) {
        width: 50%;
      }
    }

    &-second {
      display: flex;
      height: 50vh;

      img:nth-child(1) {
        width: 50%;
      }

      img:nth-child(2) {
        width: 50%;
        object-position: top;
      }
    }

    &-overlay {
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100vh;
      top: 0;
      opacity: 0.64;
      z-index: 0;
    }
  }

  &-subtitles {
    display: flex;
    flex-direction: column-reverse;
    color: $primary-lightest;

    width: 100%;
    gap: 120px;

    @media (min-width: 960px) {
      flex-direction: row;
      align-items: center;
      gap: 0;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    h2 {
      transition: all 0.3s ease-in-out;
      margin: 0 12px;
      font-size: 30px;
      line-height: 1.5;

      @media (min-width: 720px) {
        margin-left: 60px;
      }
      @media (min-width: 480px) {
        font-size: 42px;
      }

      @media (min-width: 980px) {
        font-size: 50px;
        margin-left: 120px;
      }
    }

    .green {
      color: $primary-green;
    }
  }
}

.skill-store-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 0 12px;

  @media (min-width: 720px) {
    margin: 0 120px;
  }

  a {
    img {
      width: 180px;
      height: 60px;
    }
  }
}
